export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";

export const APPS_LOADING = "APPS_LOADING";
export const APPS_ERROR = "APPS_ERROR";
export const APPS_LIST = "APPS_LIST";
export const DELETED_APPS_LIST = "DELETED_APPS_LIST";
export const DELETED_APPS_DELETE = "DELETED_APPS_DELETE";

export const APP_STYLE_LOADING = "APP_STYLE_LOADING";
export const GET_APP_STYLE = "GET_APP_STYLE";
export const APP_STYLE_ERROR = "APP_STYLE_ERROR";
export const DELETE_MENU_ITEM = "DELETE_MENU_ITEM";

export const GET_THEME = "GET_THEME";
export const THEME_STEP = "THEME_STEP";
export const APP_THEME_LOADING = "APP_THEME_LOADING";
export const APP_THEME_ERROR = "APP_THEME_ERROR";

export const MODULE_LOADING = "MODULE_LOADING";
export const GET_MODULE = "GET_MODULE";
export const MODULE_ERROR = "MODULE_ERROR";
export const COPY_MODULE = "COPY_MODULE";
export const CREATE_MODULE = "CREATE_MODULE";
export const EDIT_MODULE = "EDIT_MODULE";
export const GET_SINGLE_MODULE = "GET_SINGLE_MODULE";
export const GET_DYNAMIC_MODULES = "GET_DYNAMIC_MODULES";
export const Delete_MODULE = "Delete_MODULE";

export const SCREENS_LOADING = "SCREENS_LOADING";
export const GET_SCREENS = "GET_SCREENS";
export const SCREENS_ERROR = "SCREENS_ERROR";

export const CHANGE_CURRENT_MENU_ITEM = "CHANGE_CURRENT_MENU_ITEM";
export const GET_SCREEN = "GET_SCREEN";
export const SCREEN_LOADING = "SCREEN_LOADING";
export const INCREASE_HEIGHT = "INCREASE_HEIGHT";

export const STYLES_LOADING = "STYLES_LOADING";
export const GET_STYLES = "GET_STYLES";
export const STYLES_ERROR = "STYLES_ERROR";
export const CHANGE_COMPONENT = "CHANGE_COMPONENT";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const CHANGE_HEADER_FOOTER = "CHANGE_HEADER_FOOTER";
export const CHANGE_SCREEN = "CHANGE_SCREEN";

export const EDIT_COMPONENT = "EDIT_COMPONENT";
export const COMPONENT_ERROR = "COMPONENT_ERROR";
export const DELETE_COMPONENT = "DELETE_COMPONENT";

export const COPY_COMPONENT = "COPY_COMPONENT";

export const MODULETYPE_LOADING = "MODULETYPE_LOADING";
export const GET_MODULETYPE = "GET_MODULETYPE";
export const MODULETYPE_ERROR = "MODULETYPE_ERROR";
export const LIST_MODULETYPE = "LIST_MODULETYPE";

export const DELETE_MODULETYPE = "DELETE_MODULETYPE";
export const EDIT_MODULETYPE = "EDIT_MODULETYPE";
export const CREATE_MODULETYPE = "CREATE_MODULETYPE";

export const FORM_RULES_LOADING = "FORM_RULES_LOADING";
export const GET_FORM_RULES = "GET_FORM_RULES";
export const FORM_RULES_ERROR = "FORM_RULES_ERROR";

export const APP_RULES_LOADING = "APP_RULES_LOADING";
export const GET_APP_RULES = "GET_APP_RULES";
export const APP_RULES_ERROR = "APP_RULES_ERROR";

export const APP_SHOW = "APP_SHOW";
export const EDIT_APP = "EDIT_APP";
export const DELETE_APP = "DELETE_APP";

export const TABS_AND_fORMS_ERROR = "TABS_AND_fORMS_ERROR";
export const GET_TABS_AND_fORMS = "GET_TABS_AND_fORMS";
export const TABS_AND_fORMS_LOADING = "TABS_AND_fORMS_LOADING";
export const GET_fORMS = "GET_fORMS";
export const CHANGE_TAB = "CHANGE_TAB";
export const CREATE_TAB = "CREATE_TAB";
export const DELETE_TAB = "DELETE_TAB";
export const EDIT_TAB = "EDIT_TAB";

export const ACTION_LOADING = "ACTION_LOADING";
export const GET_ACTION = "GET_ACTION";
export const ACTION_ERROR = "ACTION_ERROR";

export const CORE_COMPONENT_LOADING = "CORE_COMPONENT_LOADING";
export const CORE_COMPONENT_ERROR = "CORE_COMPONENT_ERROR";
export const LIST_CORE_COMPONENT = "LIST_CORE_COMPONENT";
export const CREATE_FORM = "CREATE_FORM";
export const EDIT_FORM = "EDIT_FORM";
export const DELETE_FORM = "DELETE_FORM";
export const COPY_FORM = "COPY_FORM";
export const GET_SINGLE_FORM = "GET_SINGLE_FORM";

export const FIELD_NAMES_LOADING = "FIELD_NAMES_LOADING";
export const GET_FIELD_NAMES = "GET_FIELD_NAMES";
export const FIELD_NAMES_ERROR = "FIELD_NAMES_ERROR";

export const ITEMS_LOADING = "ITEMS_LOADING";
export const GET_ITEMS = "GET_ITEMS";
export const ITEMS_ERROR = "ITEMS_ERROR";

export const FILTER_LOADING = "FILTER_LOADING";
export const GET_FILTERS = "GET_FILTERS";
export const ADD_FILTER = "ADD_FILTER";
export const FILTER_ERROR = "FILTER_ERROR";

export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const GET_CATEGORY_VALUES = "GET_CATEGORY_VALUES";
export const CATEGORY_VALUES_LOADING = "CATEGORY_VALUES_LOADING";
export const CATEGORY_VALUES_ERROR = "CATEGORY_VALUES_ERROR";

export const TAGS_LOADING = "TAGS_LOADING";
export const GET_TAGS = "GET_TAGS";
export const TAGS_ERROR = "TAGS_ERROR";

export const INDUSTRIES_ERROR = "INDUSTRIES_ERROR";
export const INDUSTRIES_LIST = "INDUSTRIES_LIST";
export const INDUSTRIES_LOADING = "INDUSTRIES_LOADING";
export const DELETE_INDUSTRIES = "DELETE_INDUSTRIES";
export const EDIT_INDUSTRIES = "EDIT_INDUSTRIES";
export const CREATE_INDUSTRIES = "CREATE_INDUSTRIES";

export const TEMPLATEAPPS_ERROR = "TEMPLATEAPPS_ERROR";
export const TEMPLATEAPPS_LIST = "TEMPLATEAPPS_LIST";
export const TEMPLATEAPPS_LOADING = "TEMPLATEAPPS_LOADING";
export const DELETE_TEMPLATEAPPS = "DELETE_TEMPLATEAPPS";
export const EDIT_TEMPLATEAPPS = "EDIT_TEMPLATEAPPS";
export const CREATE_TEMPLATEAPPS = "CREATE_TEMPLATEAPPS";

export const PACKAGES_LOADING = "PACKAGES_LOADING";
export const PACKAGES_ERROR = "PACKAGES_ERROR";
export const PACKAGES_LIST = "PACKAGES_LIST";

export const LANGUAGES_LOADING = "LANGUAGES_LOADING";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const LANGUAGES_ERROR = "LANGUAGES_ERROR";

export const GOOGLE_FORMS_LOADING = "GOOGLE_FORMS_LOADING";
export const GET_GOOGLE_FORMS = "GET_GOOGLE_FORMS";
export const GOOGLE_FORMS_ERROR = "GOOGLE_FORMS_ERROR";
export const GOOGLE_FORMS_ADD = "GOOGLE_FORMS_ADD";

export const CREATE_RULES = "CREATE_RULES";
export const EDIT_RULES = "EDIT_RULES";
export const DELETE_RULES = "DELETE_RULES";
export const RULES_LOADING = "RULES_LOADING";
export const GET_RULES = "GET_RULES";
export const RULES_ERROR = "RULES_ERROR";

//entities
export const ENTITIES_ERROR = "ENTITIES_ERROR";
export const ENTITIES_LIST = "ENTITIES_LIST";
export const ENTITIES_LOADING = "ENTITIES_LOADING";

export const STATISTICS_LOADING = "STATISTICS_LOADING";
export const GET_STATISTICS = "GET_STATISTICS";
export const STATISTICS_ERROR = "STATISTICS_ERROR";
export const DELETE_STATISTICS = "DELETE_STATISTICS";
export const EDIT_STATISTICS = "EDIT_STATISTICS";

export const APP_TYPE_LOADING = "APP_TYPE_LOADING";
export const GET_APP_TYPE = "GET_APP_TYPE";
export const APP_TYPE_ERROR = "APP_TYPE_ERROR";
export const APP_LOADING = "APP_LOADING";

export const EDIT_CORE_COMPONENT = "EDIT_CORE_COMPONENT";
export const CREATE_CORE_COMPONENT = "CREATE_CORE_COMPONENT";

export const APP_TYPES_LOADING = "APP_TYPES_LOADING";
export const APP_TYPES_ERROR = "APP_TYPES_ERROR";
export const GET_APP_TYPES = "GET_APP_TYPES";

export const ACTION_ALL_LOADING = "ACTION_ALL_LOADING";
export const ACTION_ALL_ERROR = "ACTION_ALL_ERROR";
export const GET_ACTION_ALL = "GET_ACTION_ALL";

export const DELETE_CORE_COMPONENT = "DELETE_CORE_COMPONENT";

export const CORE_LAYOUT_ERROR = "CORE_LAYOUT_ERROR";
export const CORE_LAYOUT_LOADING = "CORE_LAYOUT_LOADING";
export const CREATE_CORE_LAYOUT = "CREATE_CORE_LAYOUT";
export const EDIT_CORE_LAYOUT = "EDIT_CORE_LAYOUT";
export const DELETE_CORE_LAYOUT = "DELETE_CORE_LAYOUT";
export const LIST_CORE_LAYOUT = "LIST_CORE_LAYOUT";

export const DESIGN_ERROR = "DESIGN_ERROR";
export const DESIGN_LOADING = "DESIGN_LOADING";
export const CREATE_DESIGN = "CREATE_DESIGN";
export const EDIT_DESIGN = "EDIT_DESIGN";
export const LIST_DESIGN = "LIST_DESIGN";
export const DELETE_DESIGN = "DELETE_DESIGN";

export const MENU_LOADING = "MENU_LOADING";
export const MENU_ERROR = "MENU_ERROR";
export const CREATE_MENU = "CREATE_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const LIST_MENU = "LIST_MENU";

export const HEADER_LOADING = "HEADER_LOADING";
export const HEADER_ERROR = "HEADER_ERROR";
export const GET_HEADER = "GET_HEADER";
export const FOOTER_LOADING = "FOOTER_LOADING";
export const FOOTER_ERROR = "FOOTER_ERROR";
export const GET_FOOTER = "GET_FOOTER";

export const DEFAULT_LOADING = "DEFAULT_LOADING";
export const DEFAULT_ERROR = "DEFAULT_ERROR";
export const CREATE_DEFAULT = "CREATE_DEFAULT";
export const GET_DEFAULT = "GET_DEFAULT";
export const LIST_DEFAULT = "LIST_DEFAULT";
export const EDIT_DEFAULT = "EDIT_DEFAULT";
export const DELETE_DEFAULT = "DELETE_DEFAULT";

export const EDIT_MENU_ITEM_ERROR = "EDIT_MENU_ITEM_ERROR";
export const EDIT_MENU_ITEM = "EDIT_MENU_ITEM";
export const EDIT_MENU_ITEM_LOADING = "EDIT_MENU_ITEM_LOADING";

export const GET_INTERNAL_ERROR = "GET_INTERNAL_ERROR";
export const GET_INTERNAL = "GET_INTERNAL";
export const GET_INTERNAL_LOADING = "GET_INTERNAL_LOADING";

export const COMPONENT_CATEGORY_ERROR = "COMPONENT_CATEGORY_ERROR";
export const COMPONENT_CATEGORY_LIST = "COMPONENT_CATEGORY_LIST";
export const COMPONENT_CATEGORY_LOADING = "COMPONENT_CATEGORY_LOADING";
export const DELETE_COMPONENT_CATEGORY = "DELETE_COMPONENT_CATEGORY";
export const CREATE_COMPONENT_CATEGORY = "CREATE_COMPONENT_CATEGORY";
export const EDIT_COMPONENT_CATEGORY = "EDIT_COMPONENT_CATEGORY";

export const SUB_INDUSTRIES_LOADING = "SUB_INDUSTRIES_LOADING";
export const SUB_INDUSTRIES_ERROR = "SUB_INDUSTRIES_ERROR";
export const SUB_INDUSTRIES_LIST = "SUB_INDUSTRIES_LIST";

export const SYSTEM_PACKAGES_LOADING = "SYSTEM_PACKAGES_LOADING";
export const SYSTEM_PACKAGES_ERROR = "SYSTEM_PACKAGES_ERROR";
export const SYSTEM_PACKAGES_LIST = "SYSTEM_PACKAGES_LIST";
export const SYSTEM_PACKAGES_EDIT = "SYSTEM_PACKAGES_EDIT";

export const SYSTEM_ENTITIES_LOADING = "SYSTEM_ENTITIES_LOADING";
export const SYSTEM_ENTITIES_ERROR = "SYSTEM_ENTITIES_ERROR";
export const SYSTEM_ENTITIES_LIST = "SYSTEM_ENTITIES_LIST";
export const SYSTEM_ENTITIES_EDIT = "SYSTEM_ENTITIES_EDIT";

export const SYSTEM_ENTITIES_PARENT_LOADING = "SYSTEM_ENTITIES_PARENT_LOADING";
export const SYSTEM_ENTITIES_PARENT_ERROR = "SYSTEM_ENTITIES_PARENT_ERROR";
export const SYSTEM_ENTITIES_PARENT_LIST = "SYSTEM_ENTITIES_PARENT_LIST";
export const SYSTEM_ENTITIES_FEATURE_LOADING =
  "SYSTEM_ENTITIES_FEATURE_LOADING";
export const SYSTEM_ENTITIES_FEATURE_ERROR = "SYSTEM_ENTITIES_FEATURE_ERROR";
export const SYSTEM_ENTITIES_FEATURE_LIST = "SYSTEM_ENTITIES_FEATURE_LIST";

export const ENTITIES_FEATURE_LOADING = "ENTITIES_FEATURE_LOADING";
export const ENTITIES_FEATURE_ERROR = "ENTITIES_FEATURE_ERROR";
export const ENTITIES_FEATURE_LIST = "ENTITIES_FEATURE_LIST";

export const BUTTONS_ERROR = "BUTTONS_ERROR";
export const BUTTONS_LIST = "BUTTONS_LIST";
export const BUTTONS_LOADING = "BUTTONS_LOADING";
export const DELETE_BUTTONS = "DELETE_BUTTONS";
export const CREATE_BUTTONS = "CREATE_BUTTONS";
export const EDIT_BUTTONS = "EDIT_BUTTONS";

export const COPY_APP = "COPY_APP";

export const MENU_ITEM_LOADING = "MENU_ITEM_LOADING";
export const MENU_ITEM_ERROR = "MENU_ITEM_ERROR";
export const GET_MENU_ITEM = "GET_MENU_ITEM";

export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const LIST_PAYMENT = "LIST_PAYMENT";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const STATUS_PAYMENT = "STATUS_PAYMENT";
export const STATUS_PAYMENT_LOADING = "STATUS_PAYMENT_LOADING";
export const STATUS_PAYMENT_ERROR = "STATUS_PAYMENT_ERROR";

export const COPY_MENU_ITEM_LOADING = "COPY_MENU_ITEM_LOADING";
export const COPY_MENU_ITEM_ERROR = "COPY_MENU_ITEM_ERROR";
export const COPY_MENU_ITEM = "COPY_MENU_ITEM";

export const RECOMMEND_LOADING = "RECOMMEND_LOADING";
export const GET_RECOMMEND = "GET_RECOMMEND";
export const RECOMMEND_ERROR = "RECOMMEND_ERROR";

export const SYSTEM_PACKAGES_DELETE = "SYSTEM_PACKAGES_DELETE";

export const CHANGE_SHEET_COMPONENT = "CHANGE_SHEET_COMPONENT";
export const CHANGE_SHEET_COMPONENT_LOADING = "CHANGE_SHEET_COMPONENT_LOADING";

export const COLOR_PALETTE_ERROR = "COLOR_PALETTE_ERROR";
export const COLOR_PALETTE_LIST = "COLOR_PALETTE_LIST";
export const COLOR_PALETTE_LOADING = "COLOR_PALETTE_LOADING";
export const CREATE_COLOR_PALETTE = "CREATE_COLOR_PALETTE";
export const DELETE_COLOR_PALETTE = "DELETE_COLOR_PALETTE";
export const EDIT_COLOR_PALETTE = "EDIT_COLOR_PALETTE";

export const WEBSLIDER_LOADING = "WEBSLIDER_LOADING";
export const WEBSLIDER_ERROR = "WEBSLIDER_ERROR";
export const CREATE_WEBSLIDER = "CREATE_WEBSLIDER";

export const OBJECTIVE_ERROR = "OBJECTIVE_ERROR";
export const OBJECTIVE_LIST = "OBJECTIVE_LIST";
export const OBJECTIVE_LOADING = "OBJECTIVE_LOADING";
export const CREATE_OBJECTIVE = "CREATE_OBJECTIVE";
export const DELETE_OBJECTIVE = "DELETE_OBJECTIVE";
export const EDIT_OBJECTIVE = "EDIT_OBJECTIVE";

export const APP_SYSTEM_ERROR = "APP_SYSTEM_ERROR";
export const APP_SYSTEM_LIST = "APP_SYSTEM_LIST";
export const APP_SYSTEM_LOADING = "APP_SYSTEM_LOADING";
export const CREATE_APP_SYSTEM = "CREATE_APP_SYSTEM";
export const DELETE_APP_SYSTEM = "DELETE_APP_SYSTEM";
export const EDIT_APP_SYSTEM = "EDIT_APP_SYSTEM";

export const APPS_TYPES_ERROR = "APPS_TYPES_ERROR";
export const APPS_TYPES_LIST = "APPS_TYPES_LIST";
export const APPS_TYPES_LOADING = "APPS_TYPES_LOADING";
export const CREATE_APPS_TYPES = "CREATE_APPS_TYPES";
export const DELETE_APPS_TYPES = "DELETE_APPS_TYPES";
export const EDIT_APPS_TYPES = "EDIT_APPS_TYPES";

export const SYSTEM_LOCALIZATIONS_ERROR = "SYSTEM_LOCALIZATIONS_ERROR";
export const SYSTEM_LOCALIZATIONS_LIST = "SYSTEM_LOCALIZATIONS_LIST";
export const SYSTEM_LOCALIZATIONS_LOADING = "SYSTEM_LOCALIZATIONS_LOADING";
export const EDIT_SYSTEM_LOCALIZATIONS = "EDIT_SYSTEM_LOCALIZATIONS";

export const EDIT_ENTITY_LOCALIZATIONS = "EDIT_ENTITY_LOCALIZATIONS";
export const ENTITY_LOCALIZATIONS_ERROR = "ENTITY_LOCALIZATIONS_ERROR";
export const ENTITY_LOCALIZATIONS_LIST = "ENTITY_LOCALIZATIONS_LIST";
export const ENTITY_LOCALIZATIONS_LOADING = "ENTITY_LOCALIZATIONS_LOADING";

export const EDIT_FILE_FIELDS_AR = "EDIT_FILE_FIELDS_AR";
export const FILE_FIELDS_AR_ERROR = "FILE_FIELDS_AR_ERROR";
export const FILE_FIELDS_AR_LIST = "FILE_FIELDS_AR_LIST";
export const FILE_FIELDS_AR_LOADING = "FILE_FIELDS_AR_LOADING";

export const SYSTEM_ENTITIES_COPY = "SYSTEM_ENTITIES_COPY";
export const SYSTEM_ENTITIES_DELETE = "SYSTEM_ENTITIES_DELETE";

