import { createReducer } from "@reduxjs/toolkit";
import {
  ENTITIES_ERROR,
  ENTITIES_LIST,
  ENTITIES_LOADING,
  SYSTEM_ENTITIES_LOADING,
  SYSTEM_ENTITIES_ERROR,
  SYSTEM_ENTITIES_LIST,
  SYSTEM_ENTITIES_EDIT,
  SYSTEM_ENTITIES_PARENT_LOADING,
  SYSTEM_ENTITIES_PARENT_ERROR,
  SYSTEM_ENTITIES_PARENT_LIST,
  SYSTEM_ENTITIES_FEATURE_LOADING,
  SYSTEM_ENTITIES_FEATURE_ERROR,
  SYSTEM_ENTITIES_FEATURE_LIST,
  ENTITIES_FEATURE_LOADING,
  ENTITIES_FEATURE_ERROR,
  ENTITIES_FEATURE_LIST,
  SYSTEM_ENTITIES_COPY,
  SYSTEM_ENTITIES_DELETE,
} from "../actions/types";

const filterParentState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  allPages: 0,
  thisPage: 0,
};

const filterFeatureState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  allPages: 0,
  thisPage: 0,
};

const systemState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  allPages: 0,
  thisPage: 0,
  feature: filterFeatureState,
  parent: filterParentState,
};

const featureState = {
  error: "",
  loading: false,
  data: [],
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  system: systemState,
  feature: featureState,
};

const entitiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ENTITIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ENTITIES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(ENTITIES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(ENTITIES_FEATURE_LOADING, (state) => {
      state.feature.loading = true;
    })
    .addCase(ENTITIES_FEATURE_ERROR, (state, { payload }) => {
      state.feature.error = payload;
      state.feature.loading = false;
    })
    .addCase(ENTITIES_FEATURE_LIST, (state, { payload }) => {
      state.feature.data = payload;
      state.feature.loading = false;
    })
    .addCase(SYSTEM_ENTITIES_LOADING, (state) => {
      state.system.loading = true;
    })
    .addCase(SYSTEM_ENTITIES_ERROR, (state, { payload }) => {
      state.system.error = payload;
      state.system.loading = false;
    })
    .addCase(SYSTEM_ENTITIES_LIST, (state, { payload }) => {
      state.system.loading = false;
      state.system.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.system.data, ...payload.data];
      state.system.allPages = payload.allPages;
      state.system.thisPage = payload.thisPage;
    })
    .addCase(SYSTEM_ENTITIES_EDIT, (state, { payload }) => {
      const index = state.system.data.findIndex((e) => e?.id === payload?.id);
      state.system.data[index] = payload;
      state.system.loading = false;
    })
    .addCase(SYSTEM_ENTITIES_COPY, (state, { payload }) => {
      state.system.push(payload);
      state.system.loading = false;
    })
    .addCase(SYSTEM_ENTITIES_DELETE, (state, { payload }) => {
      state.system.data = state.system.data.filter(
        (e) => e?.id !== payload?.id
      );
      state.system.loading = false;
    })

    .addCase(SYSTEM_ENTITIES_PARENT_LOADING, (state) => {
      state.system.parent.loading = true;
    })
    .addCase(SYSTEM_ENTITIES_PARENT_ERROR, (state, { payload }) => {
      state.system.parent.error = payload;
      state.system.parent.loading = false;
    })
    .addCase(SYSTEM_ENTITIES_PARENT_LIST, (state, { payload }) => {
      state.system.parent.loading = false;
      state.system.parent.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.system.parent.data, ...payload.data];
      state.system.parent.allPages = payload.allPages;
      state.system.parent.thisPage = payload.thisPage;
    })

    .addCase(SYSTEM_ENTITIES_FEATURE_LOADING, (state) => {
      state.system.feature.loading = true;
    })
    .addCase(SYSTEM_ENTITIES_FEATURE_ERROR, (state, { payload }) => {
      state.system.feature.error = payload;
      state.system.feature.loading = false;
    })

    .addCase(SYSTEM_ENTITIES_FEATURE_LIST, (state, { payload }) => {
      state.system.feature.loading = false;
      state.system.feature.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.system.feature.data, ...payload.data];
      state.system.feature.allPages = payload.allPages;
      state.system.feature.thisPage = payload.thisPage;
    });
});

export default entitiesReducer;
